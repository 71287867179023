import React from 'react';
import { Button } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import cx from 'classnames';

import { useApp } from 'contexts/appContext';

import s from './ShareButtons.module.scss';

type ShareButtonsProps = {
  className?: string;
  quote?: string;
  color?: string;
  url: string;
  customTitle?: boolean;
};
const ShareButtons: React.FC<ShareButtonsProps> = ({
  className,
  quote,
  url,
  color,
  customTitle,
  ...props
}) => {
  const { festiveInstagramPost } = useApp();
  const twitterShareTitle = quote;
  const facebookQuote = quote;
  const facebookHashtag = 'slsf';

  return (
    <div className={cx(s.shareButtonsContainer, className)} {...props}>
      {!customTitle && <span className="mr-3">Share on social media:</span>}
      <FacebookShareButton
        className="mr-2"
        quote={facebookQuote}
        hashtag={facebookHashtag}
        url={url}
      >
        <FaFacebook color={color} size={28} />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={twitterShareTitle}>
        <FaTwitter color={color} size={28} />
      </TwitterShareButton>
      {festiveInstagramPost && (
        <Button
          className="p-1 bg-transparent border-0"
          as="a"
          href={`https://www.instagram.com/${festiveInstagramPost}`}
          target="_blank"
        >
          <FaInstagram color={color} size={28} />
        </Button>
      )}
    </div>
  );
};

export default ShareButtons;

export class Validation {
  public static email = {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Invalid email address',
  };

  public static passwordComplexity = {
    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
    message: 'Password must have 1 upper case, one number and min 8 digits ',
  };

  // https://stackoverflow.com/questions/39990179/regex-for-australian-phone-number-validation
  public static mobilePhone = {
    value:
      /^(?:\+?(\d{2}))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
    message: 'Invalid Mobile number ',
  };

  public static required = 'This field is required';

  public static passwordMinLength = {
    value: 6,
    message: 'Password requires at least 6 digits',
  };
}

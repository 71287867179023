import { Stack9SortValue } from 'models/stack9SearchBodyFormat';
import { IThankYouMessage } from 'models/thankYouMessage';

import { EntityService } from './entityService';
import HttpRequestor, { BaseResponse } from './httpRequestor';

export class ThankYouMessageService extends EntityService<IThankYouMessage> {
  constructor(httpRequestor: HttpRequestor) {
    super(httpRequestor, 'message');
  }

  async getMessages(body?: any): Promise<IThankYouMessage[]> {
    const { data } = await this.search(
      {
        $select: [
          'message',
          'show_name',
          'customer.first_name',
          ...(body?.$select || []),
        ],
        $where: {
          _is_deleted: false,
          ...(body?.$where || {}),
        },
        $sort: {
          _created_at: Stack9SortValue.desc,
        },
      },
      {
        query: {
          limit: body?.limit || 10,
        },
      },
    );

    return data;
  }
  // Write down the specifics when needed

  getMoreMessages(
    limit: number,
    pageIndex: number,
    options?: any,
  ): Promise<BaseResponse<IThankYouMessage[]>> {
    return this.httpRequestor.get(
      `/${this.entity}/more?limit=${limit}&page=${pageIndex}`,
      options,
    );
  }

  getCustomerLastPendingMessage(
    options?: any,
  ): Promise<BaseResponse<IThankYouMessage>> {
    return this.httpRequestor.get(`/${this.entity}/latest-pending`, options);
  }
}
